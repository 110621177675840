import { createStore } from 'vuex'
import axios from 'axios'
import LoginService from '@/services/LoginService.js'
import moment from 'moment/moment.js'
import * as ethutil from 'ethereumjs-util'

export default createStore({
    state: {
        hash: '',
        address: '',
        role: '',
        permissions: [],
        zone: [],
        private: {},
        filters: {},
        user: {},
        users: [],
        userLog: [],
        videos: [],
        videoLog: [],
        links: [],
        clinics: [],
        treatmentMedications: [],
        preventativeMedications: [],
        suggestedAdditions: [],
        medicineLog: [],
        countryLog: [],
        countriesList: [],
        notifications: [],
        previousPage: null,
        currentVersion: true,
    },
    mutations: {
        SET_USER_HASH(state, data) {
            let quantity = '8',
                type = 'h',
                hash = null;

            if (data) {
                quantity = data.trust ? '90' : '8';
                type = data.trust ? 'd' : 'h';
                hash = data.hash
            }

            let object = { 'hash': hash, 'expires': moment().add(quantity, type).format('x'), }
            window.localStorage.setItem('hash', JSON.stringify(object));

            state.hash = hash
        },
        SET_USER_ADDRESS(state, data) {
            let address = null,
                quantity = '8',
                type = 'h';

            if (data) {
                quantity = data.trust ? '90' : '8';
                type = data.trust ? 'd' : 'h';
                address = data.address
            }

            let object = { 'address': address, 'expires': moment().add(quantity, type).format('x'), }
            window.localStorage.setItem('address', JSON.stringify(object));

            state.address = address
            console.log('End SET_USER_ADDRESS')
        },
        SET_PRIVATE_DATA(state, data) {
            state.private = data
        },
        SET_USER_DATA(state, userData) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${userData.response}`
        },
        SET_USER_PERMISSION(state, data) {
            let object = {
                'role': data.user.role,
                'permissions': data.user.permissions,
                'zone': data.user.zone
            }
            window.localStorage.setItem('permission', JSON.stringify(object));

            state.role = data.user.role
            state.permissions = data.user.permissions
            state.zone = data.user.zone
        },
    },
    actions: {
        async login({ commit, dispatch, state }, credentials) {
            await dispatch('getHash', {
                address: credentials.address,
                trust: credentials.trustComputer
            })
            commit('SET_USER_ADDRESS', {
                address: credentials.address,
                trust: credentials.trustComputer
            })
            const serverHash = state.hash

            const hash = Buffer.from(serverHash, 'hex')
            const signature = ethutil.ecsign(hash, Buffer.from(credentials.privateKey, 'hex'))
            console.log(credentials.privateKey);
            console.log(serverHash);

            const signData = {
                hash: serverHash,
                sigR: signature.r.toString('hex'),
                sigS: signature.s.toString('hex'),
                sigV: signature.v,
            }

            console.log(signData);

            commit('SET_PRIVATE_DATA', signData)

            signData.trustComputer = credentials.trustComputer;
            
            return LoginService.PostLogin(signData)
                .then(async ({ data }) => {
                    console.log('Then post login')
                    console.log(data)
                    commit('SET_USER_DATA', data)
                    //let user = await LoginService.GetUserInfo(credentials.address);
                    //console.log(user);
                    //window.intercomSettings.name = user.data.user.firstName + ' ' + user.data.user.lastName;
                    //window.intercomSettings.email = user.data.user.email;
                    //window.intercomSettings.user_id = user.data.user.address;

                    //await dispatch('getPermissions', credentials.address)
                })
        },
        getHash({ commit }, user) {
            console.log("Gettin Hash")
            console.log(user.address)
            return LoginService.GetHash(user.address)
                .then(({ data }) => {
                    console.log(data.response)
                    commit('SET_USER_HASH', { hash: data.response, trust: user.trust })
                })
        },
        getPermissions({ commit }, address) {
            return LoginService.getPermissions(address)
                .then(({ data }) => {
                    commit('SET_USER_PERMISSION', data)
                })
        },
        removeHash({ commit }) {
            commit('SET_USER_HASH', null)
        },
    },
    getters: {

    }
});