<template>
  <div v-if="loading == true" class="preloader mt-4"></div>
  <section v-if="loading == false" class="container">
    <h1 class="title">{{ surveyName }}</h1>
    <hr class="separator" />
    <div v-if="hasBanner" class="survey-banner"></div>
    <hr />
    <div v-for="(question, index) in survey" :key="index" class="question">
      <!--Set question description-->
      <label>{{ question.question }}</label
      ><br /><br />

      <!--Cast input-->
      <input
        v-if="
          question.type == 'text' ||
          question.type == 'number' ||
          question.type == 'date'
        "
        v-model="answers[index].value"
        :type="question.type"
        :placeholder="question.placeholder"
        class="shadow-sm"
        @change="CheckForm(true)"
      />

      <!--Cast range-->
      <input
        v-if="question.type == 'range'"
        v-model="answers[index].value"
        :type="question.type"
        min="0"
        max="10"
        class="shadow-sm"
        @change="CheckForm(true)"
      />

      <!--Cast check-->
      <div
        v-if="question.type == 'radio' || question.type == 'checkbox'"
        class="row"
      >
        <div
          v-for="element in question.elements"
          :key="element._id"
          class="col-12 col-md-6"
        >
          <label :class="question.type">
            {{ element.value }}
            <input
              v-model="answers[index].value"
              :type="question.type"
              :id="element._id"
              :name="question._id"
              :value="element.value"
              @change="CheckForm(true)"
            />
            <span></span>
          </label>
        </div>
      </div>

      <!--Cast select-->
      <select
        v-if="question.type == 'select'"
        v-model="answer[index].value"
        class="shadow-sm"
        @change="CheckForm(true)"
      >
        <option value="">{{ question.placeholder }}</option>
        <option
          v-for="element in question.elements"
          :key="element._id"
          :value="element.value"
        >
          {{ element.value }}
        </option>
      </select>
      <hr />
    </div>
    <p v-if="error != ''" class="alert-error">{{ error }}</p>
    <div class="row">
      <button
        v-on:click="SubmitSurvey"
        :disabled="isButtonDisabled"
        :class="buttonStatus + ' bh-button bh-button-full-width ml-auto mr-auto'"
      >
      <p v-if="surveyLanguage == 'en'">Submit survey</p>
      <p v-if="surveyLanguage == 'es'">Subir respuestas</p>
      <p v-if="surveyLanguage == 'pt'">Enviar pesquisa</p>
      </button>
    </div>
  </section>
</template>
<script>
import SurveyService from "@/services/SurveyService.js";

export default {
  data() {
    return {
      surveyName: "Survey",
      hasBanner: false,
      survey: [],
      answers: [],
      error: "",
      buttonStatus: "btn-disabled",
      isButtonDisabled: true,
      surveyLanguage: 'en',
      loading: true,
    };
  },
  mounted() {
    this.GetSurvey();
  },
  methods: {
    GetSurvey() {
      let id = this.$route.params.id;

      return SurveyService.GetSurveyById(id).then(async ({ data }) => {
        this.surveyName = data.response.name;
        this.hasBanner = data.response.hasBanner;
        this.survey = data.response.inputs;
        this.surveyLanguage = data.response.language;
        this.SetAnswers();
        this.loading = false;
      });
    },
    SubmitSurvey() {
      console.log(this.answers);
      this.isButtonDisabled = true;
      this.buttonStatus = "btn-disabled";

      let id = this.$route.params.id;
      let data = {
        answers: this.answers,
      };
      console.log(this.answers);
      return SurveyService.SubmitSurvey(id, data).then(async ({ data }) => {
        window.location.href = this.$route.query.redirect_url;
      });
    },
    SetAnswers() {
      for (let i = 0; i < this.survey.length; i++) {
        let value = "";
        if (this.survey[i].type == "title") value = "isTitle";

        this.answers.push({
          questionId: this.survey[i]._id,
          value: value,
        });
      }
    },
    CheckForm() {
      var isCompletedForm = true;

      this.answers.forEach((element) => {
        if (!element.value) isCompletedForm = false;
      });

      if (isCompletedForm) {
        this.buttonStatus = "";
        this.isButtonDisabled = false;
      }
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/SurveyClient.css";
</style>