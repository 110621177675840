import { createRouter, createWebHistory } from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import SurveyComponent from './views/survey/SurveyClient.vue'

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('./views/Home.vue')
      },
      {
        path: '/daily',
        name: 'Daily missions',
        component: () => import('./views/Daily.vue')
      },
      {
        path: '/weekly',
        name: 'Weekly missions',
        component: () => import('./views/Weekly.vue')
      },
      {
        path: '/survey/create',
        name: 'SurveyCreate',
        component: () => import('./views/survey/SurveyCreate.vue')
      },
      {
        path: '/survey/create/:id',
        name: 'SurveyUpdate',
        component: () => import('./views/survey/SurveyCreate.vue')
      },
      {
        path: '/survey',
        name: 'Survey',
        component: () => import('./views/survey/Survey.vue')
      },
      {
        path: '/survey/sumary/:id',
        name: 'SurveyAnswers',
        component: () => import('./views/survey/SurveySumary.vue')
      },
      {
        path: '/accounts',
        name: 'Accounts',
        component: () => import('./views/Accounts.vue')
      },
      {
        path: '/missions',
        name: 'Missions',
        component: () => import('./views/missions/Missions.vue')
      },
      {
        path: '/missions/create',
        name: 'Create mission',
        component: () => import('./views/missions/MissionsCreate.vue')
      },
      {
        path: '/missions/create/:id',
        name: 'Update mission',
        component: () => import('./views/missions/MissionsCreate.vue')
      }
    ]
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'Research Dashboard',
        component: () => import('./views/Login.vue')
      },
      {
        path: '/error',
        name: 'Error',
        component: () => import('./views/Error.vue')
      }
    ]
  },
  {
    path: '/survey/:id',
    component: SurveyComponent
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
