import axios from 'axios'
import NProgress from 'nprogress'
import Swal from 'sweetalert2'
import VueCookies from "vue-cookies";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URI,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': VueCookies.get('jwt')
    },
    timeout: 6000
})

apiClient.interceptors.request.use(config => {
    NProgress.start()
    return config
})

apiClient.interceptors.response.use(response => {
    NProgress.done()
    return response
}, function(error){
    console.log(error)
    if(401 == error.response.status){
        Swal.fire({
            title: 'Session Expired',
            text: 'Your session has expired.',
            icon: 'error',
            confirmButtonText: 'Ok'
        }).then(async function() {
            location.reload()
        })
    }
    else {
        return Promise.reject(error)
    }
})

export default {
    GetSurveys(){
        return apiClient.get('/survey');
    },
    GetSurveysLimit(limit){
        return apiClient.get('/survey?amount=' + limit);
    },
    GetSurveyById(id){
        return apiClient.get('/survey/' + id)
    },
    AddSurvey(data) {
        return apiClient.post('/survey/add', data)
    },
    UpdateSurvey(id, data) {
        return apiClient.post('/survey/update/' + id, data)
    },
    SetActiveSurvey(id){
        return apiClient.post('/survey/set/' + id)
    },
    SubmitSurvey(id, data){
        return apiClient.post('/survey/submit/' + id, data)
    }
}