import axios from 'axios'
import NProgress from 'nprogress'
import Swal from 'sweetalert2'
import VueCookies from "vue-cookies";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URI,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': VueCookies.get('jwt')
    },
    timeout: 6000
})

apiClient.interceptors.request.use(config => {
    NProgress.start()
    return config
})

apiClient.interceptors.response.use(response => {
    NProgress.done()
    return response
}, function(error){
    if(401 == error.response.status){
        Swal.fire({
            title: 'Session Expired',
            text: 'Your session has expired.',
            icon: 'error',
            confirmButtonText: 'Ok'
        }).then(async function() {
            location.reload()
        })
    }
    else {
        return Promise.reject(error)
    }
})

export default {
    GetUsers() {
        return apiClient.get('/account')
    },
    AddUser(data) {
        return apiClient.post('/account/add', data)
    },
    GetUserInfo(){
        return apiClient.get('/account/info')
    },
    UpdateUserRole(data){
        return apiClient.post('/account/role/update', data)
    },
}