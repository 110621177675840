import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"
import "@/assets/css/Global.css"
import "@/assets/css/DashboardLayout.css"
import "@/assets/css/AuthLayout.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import GlobalComponents from './plugins/globalComponents'
import vue3GoogleLogin from 'vue3-google-login'

createApp(App)
    .use(router)
    .use(store)
    .use(GlobalComponents)
    .use(vue3GoogleLogin, { clientId: '673734408971-5md6cu49u2kgqs5sho6mhh351fgm60do.apps.googleusercontent.com' })
    .mount('#app')