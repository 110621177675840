<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img
          src="../assets/logo.png"
          alt=""
          class="d-inline-block align-text-top"
        />
        <h5>
          Bowhead <br />
          Health
        </h5>
      </a>
      <div
        class="collapse navbar-collapse"
        v-bind:class="{ show: show }"
        id="nav"
      ></div>
      <div class="navbar-brand">
        <div class="dropdown">
          <button
            v-on:click="New()"
            class="bh-button"
            style="padding: 5px 20px; margin-right: 10px; border-radius: 50px"
          >
            NEW +
          </button>
          <div
            id="myDropdown"
            class="create-button-content"
            v-bind:class="{ show: showCreateOptions }"
          >
            <a
              href="/missions/create"
              style="
                color: var(--color-txt-primary) !important;
                padding: 8px 0;
                text-decoration: none;
                display: block;
                text-align: center;
                width: 100%;
                border-radius: 12px;
              "
              >Mission</a
            ><br />
            <a
              href="/survey/create"
              style="
                color: var(--color-txt-primary) !important;
                padding: 8px 0;
                text-decoration: none;
                display: block;
                text-align: center;
                width: 100%;
                border-radius: 12px;
              "
              >Survey</a
            ><br />
          </div>
        </div>
        <img :src="user.picture" alt="Profile photo" class="bh-profile-photo" />
      </div>
    </div>
  </nav>
  <div class="sidenav">
    <div class="sidenav-items">
      <router-link to="/home">
        <div class="item mt-1">
          <img src="../assets/img/icons/IconHome.png" />
          <span>HOME</span>
        </div>
      </router-link>
      <router-link to="/missions">
        <div class="item">
          <img src="../assets/img/icons/IconEvent.png" />
          <span>MISSIONS</span>
        </div>
      </router-link>
      <router-link to="/survey">
        <div class="item">
          <img src="../assets/img/icons/IconSurvey.png" />
          <span>SURVEYS</span>
        </div>
      </router-link>
      <router-link to="/accounts">
        <div class="item mb-1">
          <img src="../assets/img/icons/IconEvent.png" />
          <span>ACCOUNTS</span>
        </div>
      </router-link>
    </div>
  </div>
  <div class="main">
    <router-view></router-view>
  </div>
</template>
<script>
import "boxicons";
import VueCookies from "vue-cookies";
import AuthService from "@/services/AuthService.js";

export default {
  data() {
    return {
      user: {},
      show: false,
      showCreateOptions: false,
    };
  },
  created() {
    this.VerifySession();
    this.GetUserData();
  },
  methods: {
    toggleNavbar() {
      this.show = !this.show;
    },
    VerifySession() {
      if (!VueCookies.get("jwt")) window.location.href = "/login";
    },
    GetUserData() {
      let id = this.$route.params.id;

      return AuthService.GetUserInfo()
        .then(async ({ data }) => {
          this.user = data.response;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    New() {
      this.showCreateOptions = !this.showCreateOptions;
    },
  },
};
</script>
