import axios from 'axios'
import NProgress from 'nprogress'
import Swal from 'sweetalert2'
import VueCookies from "vue-cookies";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URI,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': VueCookies.get('jwt')
    },
    timeout: 6000
})

apiClient.interceptors.request.use(config => {
    NProgress.start()
    return config
})

apiClient.interceptors.response.use(response => {
    NProgress.done()
    return response
}, function(error){
    if(401 == error.response.status){
        Swal.fire({
            title: 'Session Expired',
            text: 'Your session has expired.',
            icon: 'error',
            confirmButtonText: 'Ok'
        }).then(async function() {
            location.reload()
        })
    }
    else {
        return Promise.reject(error)
    }
})

export default {
    GetHash(address) {
        return apiClient.get('/account/challenge/?address=' + address)
    },
    PostLogin(credentials) {
        return apiClient.post('account/login', credentials)
    },
    GetPermissions(address) {
        return apiClient.get('/user/permissions/' + address)
    },
    SignUp(data) {
        return apiClient.post('/account/singup', data)
    },
    GetUserInfo(address) {
        return apiClient.get('/account/info/' + address)
    },
    GoogleLogin(data){
        const GoogleLoginClient = axios.create({
            //baseURL: process.env.VUE_APP_API_URI,
            baseURL: 'https://betterquestcrm.bowheadhealth.io/api/v1',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 6000
        });
        return GoogleLoginClient.post('/account/login/google', data);
    },
    VerifyCredentials(){
        return apiClient.get('/account/google/verify');
    }
}